<template>
	<div id="team" class="team">
		<div class="name">{{info.team_name}}</div>
		<div class="title">{{info.position_title[0]}}</div>
		
		<div class="info" v-html="info.content"></div>
	</div>
</template>

<script>
	export default {
		name: 'team_detail',
		data() {
			return{
				info:{},
				id:0
			}
		},
		created() {
			this.id = this.$route.query.id
			document.body.scrollIntoView();
			this.getList()
		},
		methods: {
			getList(){
				this.$api.get('home/index/teamsDetail',{
					id:this.id
				}).then(res=>{
					this.info = res.data
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.team{
		width: 750px;
		color: #1d2a55;
		font-size: 30px;
		margin: 0 auto;
		.name{
			color: #1d2a55;
			font-size: 48px;
			text-align: center;
			margin-top: 100px;
		}
		.title{
			font-size: 40px;
			text-align: center;
			padding: 50px 0;
			border-bottom: 1px solid #1d2a55;
			margin-bottom: 50px;
		}
		.img{
			padding-top: 50px;
		}
		::v-deep p{
			font-size: 30px !important;
			padding: 14px 0;
			img{
				width: 100%;
			}
		}
	}
	
	@media screen and(max-width:768px) {
		.team{
			width: 100%;
			padding: 16px;
			box-sizing: border-box;
			.name{
				font-size: 18px;
				margin-top: 30px;
				font-weight: 700;
			}
			.title{
				font-size: 16px;
				padding-top: 12px;
				padding-bottom: 16px;
			margin-bottom: 20px;

			}
			::v-deep p{
				font-size: 14px !important;
				padding: 10px 0;
				// line-height: 1.3em;
			}
			::v-deep span{
				// line-height: 1.3em;
				font-size: 14px !important;
			}
		}
	}
</style>
